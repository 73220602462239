@use "/src/styles/variables" as variables;
@use "../../mixins" as mixins;

.partners {
  position: relative;
  padding: 37px 0 66px;
  background: radial-gradient(471.36% 278.64% at -61.33% 214.31%, #7B4CFD 0%, rgba(123, 76, 253, 0.00) 73.85%), linear-gradient(274deg, #021639 6.68%, #081B40 19.22%, #0D1F44 31.75%, #12244A 44.29%, #16284E 56.83%, #1C2D55 69.36%, #203059 81.9%, #23335C 94.44%, #283761 106.97%, #2F3D69 119.51%, #35436F 132.05%, #424E7D 144.58%);
  border-bottom-right-radius: 50px;
  border-bottom-left-radius: 50px;

  @media (max-width: variables.$media-phone-width) {
    padding: 42px 0 44px;
  }

  &__content {
    max-width: 1228px;
    padding: 0 50px;
    margin: auto;
  }

  &__list {}
}

.partners-list {
  list-style: none;
  padding: 0;
  margin: 0;

  display: grid;
  justify-content: space-between;
  align-items: center;
  grid-template-columns: repeat(5, auto);
  grid-template-rows: auto auto;
  grid-auto-flow: column;

  @media (max-width: 900px) {
    grid-auto-flow: row;
    grid-template-columns: repeat(3, auto);

    &__item {
      margin: 10px 0;
    }
  }

  @media (max-width: variables.$media-phone-width) {
    grid-template-columns: repeat(2, 1fr);

    &__item {
      margin-top: 11px;
      margin-bottom: 11px;
    }

    &__item:nth-child(odd) {
      margin-right: 11px;
    }

    &__item:nth-child(even) {
      margin-left: 11px;
    }
  }


  //display: flex;
  //justify-content: space-between;
  //flex-wrap: wrap;
  //align-items: center;
  //
  //&__item {
  //  text-align: center;
  //  margin-bottom: 40px;
  //
  //  &:not(:last-child) {
  //    margin-right: 15px;
  //  }
  //
  //  @media (max-width: 1190px) {
  //    //flex-basis: calc(25% - 15px);
  //  }
  //
  //  @media (max-width: 768px) {
  //    //flex-basis: calc(50% - 30px);
  //    //max-width: calc(50% - 30px);;
  //    margin-left: 15px;
  //    margin-right: 15px;
  //  }
  //}
}

.partner {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: variables.$media-phone-width) {
    display: block;
  }

  &:not(&--with-child) {
    grid-row: span 2;
  }

  //&--with-child {
  //  @media (max-width: variables.$media-tablet-width) {
  //    flex-direction: row;
  //    flex-basis: 100%;
  //    max-width: 100%;
  //    justify-content: space-between;
  //  }
  //
  //  @media (max-width: variables.$media-tablet-width) {
  //    span {
  //      flex-basis: 50%;
  //      //flex-grow: 1;
  //      text-align: left;
  //    }
  //  }
  //}
  //
  //@media (max-width: variables.$media-tablet-width) {
  //  margin-left: 0;
  //  margin-right: 0;
  //}
  //
  //@media (max-width: variables.$media-tablet-width) {
  //  &:not(&--with-child) {
  //    align-items: stretch;
  //  }
  //}

  &--with-child &__img {
    margin-bottom: 9px;
  }

  &__img {
    vertical-align: middle;
    max-width: 100%;
    height: auto;
  }

  &__text {
    color: #FFFFFF;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.6px;
    text-transform: uppercase;
    text-align: center;
  }
}
