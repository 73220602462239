@use "sass:math" as math;

.timeline {
  &__content {
    max-width: 1920px;
    margin: auto;
  }

  &__events {
  }
}

.timeline-events {
  padding-bottom: 70px;
  padding-left: math.div(156, 1440) * 100%;

  display: grid;
  grid-template-rows: repeat(3, auto);
  grid-auto-flow: column;
  justify-content: start;

  overflow-x: auto;

  &::-webkit-scrollbar {
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #5cabf366;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #5cabf3;
  }
}

.timeline-event {
  &__placeholder {
    visibility: hidden;
  }

  &__achievements {
    display: flex;
    align-items: flex-end;
    margin-left: 50px;
    margin-right: 12px;
    margin-bottom: 40px;
  }

  &__achievement {
    position: relative;
    display: flex;
    align-items: center;

    //max-width: 286px;
    //width: 100%;
    //min-width: 157px;
    padding: 7px 10px;
    margin-right: 10px;

    color: #021639;
    font-size: 15px;
    line-height: 110%;

    border-radius: 8px;
    background-color: rgba(92, 171, 243, 0.40);

    &::before {
      content: url("/static/img/index/timeline/timeline-item.svg");
      position: absolute;
      top: 100%;
      left: calc(50% - 6px);
      z-index: 1;
      line-height: 0;
    }

    img + span, svg + span {
      margin-left: 10px;
      margin-right: 0;
    }

    span {
      margin-right: 10px;
    }

    &--highlighted {
      font-weight: 800;
    }

    img, svg {
      flex-shrink: 0;
    }
  }

  &__year {
    justify-self: start;
    margin-bottom: 40px;
    padding: 0 4px;

    color: #5CABF3;
    font-size: 16px;
    font-weight: 800;
    line-height: 100%;
    background-color: #FFFFFF;
    position: relative;
    z-index: 1;
  }

  &__info {
    min-width: 275px;
    position: relative;
    padding-right: 60px;

    &::before {
      content: '';
      position: absolute;
      top: -48px;
      width: calc(100% + 58px);
      height: 2px;
      border-top: 2px dashed #5CABF3;
    }
  }

  &__title {
    display: flex;
    align-items: center;

    color: #021639;
    font-size: 20px;
    font-weight: 700;
    line-height: 120%;
    margin: 0 0 20px;

    svg {
      margin-right: 4px;
    }
  }

  &__desc {
    font-family: inherit;
    color: #0D0D0D;
    font-size: 15px;
    line-height: 120%;
    margin: 0 0 18px;

    &--highlighted {
      font-weight: 700;
    }
  }

  &__title, &__desc {
    max-width: 275px;
  }
}
