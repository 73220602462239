@use "/src/styles/variables" as variables;

.recognized {
  padding-top: 100px;
  padding-bottom: 100px;


  @media (max-width: variables.$media-phone-width) {
    padding-top: 64px;
    padding-bottom: 56px;
  }

  &__content {
    max-width: 1104px;
    margin: auto;
    padding: 0 43px;
  }

  &__title {
    color: #424E7D;
    font-size: 22px;
    font-weight: 700;
    letter-spacing: 2.2px;
    text-transform: uppercase;
    margin-bottom: 41px;
    text-align: center;
  }

  &__list {
  }
}

.recognized-list {
  list-style: none;
  margin: 0;
  padding: 0;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  &__item {
    margin-bottom: 10px;
    margin-left: 37.5px;
    margin-right: 37.5px;
    text-align: center;

    @media (max-width: variables.$media-tablet-width) {
      flex: 1 1 calc(33% - 75px);
      margin-bottom: 30px;
    }

    img {
      max-width: 100%;
      min-width: 62px;
      height: auto;
    }
  }
}
