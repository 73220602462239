@use "/src/styles/mixins" as mixins;
@use "/src/styles/variables" as variables;

.team {
  padding: 40px 0 160px;
  background-color: variables.$color-white;

  &__content {
    display: flex;
    justify-content: space-between;

    @media (max-width: variables.$media-tablet-width) {
      flex-direction: column;
    }
  }


  &__left {
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 20px;

    @media (max-width: variables.$media-tablet-width) {
      margin-right: 0;
    }

    &__title {
      display: flex;
      flex-direction: row;

      &__line {
        margin-right: 24px;
        width: 8px;
        background-color: variables.$color-blue-lighter;
      }
      &__text {
        @include mixins.responsiveProperty(font-size, 32, 24);
        @include mixins.responsiveProperty(line-height, 40, 30);
        font-weight: 600;
        color: #424E7D;
      }
    }

    &__desc {
      color: #424E7D;
      margin-top: 26px;
      margin-bottom: 46px;
      font-size: 16px;
      line-height: 20px;
    }
  }

  &__right {
    margin-top: auto;
    margin-bottom: auto;

    @media (max-width: variables.$media-tablet-width) {
      order: -1;
      margin-bottom: 46px;
    }

    &__img {
      max-width: 100%;
      height: auto;
    }
  }


  &__btn {
    align-self: start;
    justify-self: start;

    padding: 15px 32px;
    font-weight: 500;
    font-size: 16px;
    color: variables.$color-white;
    background-color: variables.$color-blue-lighter;
    text-decoration: none;
    border: none;

    @media (max-width: variables.$media-phone-width) {
      width: 100%;
      display: block;
      text-align: center;
    }
  }
}
