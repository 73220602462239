@use "/src/styles/variables" as variables;
@use "../../mixins" as mixins;
@use "sass:math" as math;

.advantages {
  padding-top: 95px;
  padding-bottom: 75px;

  @media (max-width: variables.$media-phone-width) {
    padding-top: 90px;
    padding-bottom: 140px;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;

    max-width: 1239px;
    margin: auto;
    padding: 0 50px;

    @media (max-width: variables.$media-desktop-width) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  &__img {
    @media (max-width: 1024px) {
      margin-right: 0;
      margin-top: 73px;
      text-align: center;
    }

    img {
      max-width: 100%;
      height: auto;
      vertical-align: middle;
    }
  }

  &__list {
    flex-basis: 397px;

    @media (max-width: variables.$media-desktop-width) {
      flex-basis: auto;
    }
  }
}

.advantages-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;

  &__item {
    flex-shrink: 0;


    @media (max-width: variables.$media-desktop-width) {
      flex: 1 0 50% !important;
    }

    @media (max-width: variables.$media-phone-width) {
      &:nth-child(odd) {
        padding-right: 15px;
      }

      &:nth-child(even) {
        padding-left: 15px;
      }
    }


    @media (max-width: 410px) {
      flex: 1 1 100% !important;

        padding-left: 0 !important;
        padding-right: 0 !important;

      &:nth-child(3) {
        padding-top: 0 !important;
      }
    }

    &:first-child {
      flex-basis: 142px;
      padding-right: 23px;
      padding-bottom: 36px;
    }

    &:nth-child(2) {
      flex-basis: 202px;
      padding-left: 23px;
      padding-bottom: 36px;
    }

    &:nth-child(3) {
      flex-basis: 147px;
      padding-top: 36px;
      padding-right: 23px;
    }

    &:nth-child(4) {
      flex-basis: 94px;
      padding-top: 36px;
      padding-left: 23px;
    }
  }
}

.advantage {
  box-sizing: content-box;
  position: relative;

  @media (max-width: variables.$media-desktop-width) {
    box-sizing: border-box;
  }

  &__title {
    max-width: 202px;
    margin: 0;

    color: #424E7D;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%;

    @media (max-width: 410px) {
      margin: 0 !important;
      max-width: 100%;
    }
  }

  &__title-new-line {
    @media (max-width: 410px) {
      display: none;
    }
  }

  &:nth-child(odd) &__title {
    margin-left: auto;

    @media (max-width: variables.$media-phone-width) {
      margin: auto;
    }
  }

  &:nth-child(even) &__title {
    margin: 0;

    @media (max-width: variables.$media-phone-width) {
      margin: auto;
    }
  }

  &__digit {
    display: block;
    font-size: 28px;
    font-weight: 800;
    line-height: 38px;
    margin-bottom: 10px;
    white-space: nowrap;

    &::before {
      display: inline-block;
      margin-right: 10px;
      vertical-align: middle;
    }
  }

  &:first-child &__digit::before {
    content: url("/static/img/index/advantages/icon-check.svg");
  }

  &:nth-child(2) &__digit::before {
    content: url("/static/img/index/advantages/icon-heartbeat.svg");
  }

  &:nth-child(3) &__digit::before {
    content: url("/static/img/index/advantages/icon-profile.svg");
  }

  &:nth-child(4) &__digit::before {
    content: url("/static/img/index/advantages/icon-flag.svg");
  }
}
