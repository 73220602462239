@use "/src/styles/variables" as variables;

.investors {
  padding-bottom: 100px;

  @media (max-width: variables.$media-phone-width) {
    padding-bottom: 42px;
  }

  &__content {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__title {
    color: #424E7D;
    font-size: 22px;
    font-weight: 700;
    letter-spacing: 2.2px;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 40px;
  }

  &__list {
  }
}

.investors-list {
  list-style: none;
  margin: 0;
  padding: 0;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  &__item {
    margin: 0 40px 40px;

    @media (max-width: variables.$media-phone-width) {
      margin-bottom: 18px;
    }

    img {
      max-width: 100%;
      height: auto;
      vertical-align: middle;
    }
  }
}
