@use "../../variables" as variables;
@use "../../mixins" as mixins;
@use "sass:math" as math;

$header-padding-vertical: 48px;

.hero {
  background: radial-gradient(471.36% 278.64% at -61.33% 214.31%, #7B4CFD 0%, rgba(123, 76, 253, 0.00) 73.85%), linear-gradient(274deg, #021639 6.68%, #081B40 19.22%, #0D1F44 31.75%, #12244A 44.29%, #16284E 56.83%, #1C2D55 69.36%, #203059 81.9%, #23335C 94.44%, #283761 106.97%, #2F3D69 119.51%, #35436F 132.05%, #424E7D 144.58%);
  // overflow-x hidden gives overflow-y: scroll, so we need to use overflow: hidden on main tag
  //overflow-x: hidden;
  padding-bottom: 40px;

  &__header {
    margin-bottom: calc(80px - $header-padding-vertical);

    @media (max-width: variables.$media-tablet-width) {
      margin: 0;
    }
  }

  &__content {
    max-width: 1256px;
    padding: 0 20px;
    margin: 0 auto;
    position: relative;
  }

  &__body {
  }

  &__devices {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    img {
      max-width: 100%;
    }
      img:first-child {
        position: absolute;
        right: -20px;
        top: 0;
        z-index: 1;

        @media (max-width: variables.$media-desktop-width) {
          max-width: 70%;
        }

        @media (max-width: variables.$media-phone-width) {
          top: 70px;
          right: 0;

          max-width: 430px;
        }

        @media (max-width: 430px) {
          top: 140px;
          max-width: 100%;
        }
      }

    img:nth-child(2) {
      position: absolute;
      bottom: 0;
      left: math.div(340, 1440) * 100%;
      z-index: 1;


      @media (max-width: variables.$media-desktop-width) {
        left: 10%;
      }

      @media (max-width: variables.$media-tablet-width) {
        display: none;
        //left: 0;
        //bottom: -150px;
        //z-index: 1;
      }

      @media (max-width: variables.$media-phone-width) {
        //bottom: 0;
        //left: -50px;
      }

    }
  }

  &__warning {
    position: relative;
    z-index: 5;

    display: block;
    padding-right: 50px;
    margin-bottom: 37px;

    text-align: right;
    color: #FFF;
    opacity: 0.7;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.13px;

    @media (max-width: variables.$media-phone-width) {
      text-align: left;
      margin-bottom: 14px;
    }
  }

  &__title-new-line {
    @media (max-width: variables.$media-tablet-width) {
      display: none;
    }
  }

  &__title {
    position: relative;
    z-index: 2;
    max-width: 901px;
    margin: 0;

    color: #FFF;
    @include mixins.responsiveProperty(font-size, 76, 48);
    font-weight: 500;
    line-height: 90%;
    letter-spacing: -1.52px;
    text-transform: uppercase;

    &--highlighted {
      color: #3DCBB1;
    }
  }

  &__body-content {

  }

  &__startx {
    display: inline-block;
    margin-top: 232px;
    position: relative;
    z-index: 4;

    margin-bottom: 33px;

    @media (max-width: variables.$media-phone-width) {
      margin-top: 280px;

      img {
        width: 98px;
        height: 38px;
      }
    }

    @media (max-width: 430px) {
      margin-top: 270px;
    }
  }

  &__body-img {

    img {
      max-width: 100%;
      height: auto;
      vertical-align: middle;
    }
  }

  &__link {
    display: block;
    color: #F5F4FA;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.18px;
    text-decoration: 3px underline #5459B7;
    text-underline-offset: 7px;
    margin-bottom: 31px;
  }
}

