@use "../../variables" as variables;
@use "/src/styles/mixins" as mixins;

.mission {
  position: relative;
  @include mixins.responsiveProperty(padding-top, 183, 144);
  @include mixins.responsiveProperty(padding-bottom, 189, 181);

  background: url("/static/img/index/mission/mission-bg.png") no-repeat center / cover;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(98deg, #3F3A8F 13.95%, #03173A 79.78%);
    mix-blend-mode: color;
  }

  &__content {
    text-align: center;
  }

  &__title {
    margin-top: 0;
    margin-bottom: 28px;

    @include mixins.responsiveProperty(font-size, 22, 16);
    font-weight: 700;
    letter-spacing: 2.2px;
    text-transform: uppercase;
    color: #FFFFFF;
  }

  &__desc {
    max-width: 1062px;
    margin: 0 auto;
    text-align: center;
    background: linear-gradient(90deg, rgba(61, 203, 177, 0) 3%, rgba(92, 171, 243, 0) 3%, #424E7D 56%, rgba(92, 171, 243, 0) 100%);
    padding-bottom: 32px;

    @include mixins.responsiveProperty(font-size, 32, 24);
    @include mixins.responsiveProperty(line-height, 42, 34);
    @include mixins.responsiveProperty(padding-top, 27, 22);
    @include mixins.responsiveProperty(padding-left, 83, 27);
    @include mixins.responsiveProperty(padding-right, 83, 27);

    font-weight: 300;
    letter-spacing: 0.32px;
    color: #FFF;
  }

}
